// @flow

import { ASSET_TYPE } from '../constants/assets';
import { getRouteFromAssetType } from './assets';
import { isForSale } from './tools';

export const enrichPaginatableAssets = (pagination: PaginatableAssets) => {
  if (!pagination.items || pagination.items.length === 0) {
    return pagination;
  }
  pagination.items = pagination.items.map((asset) => ({
    ...asset,
    isForSale: isForSale(asset),
    relativeUrl: `${getRouteFromAssetType(asset.type)}${
      asset.type === ASSET_TYPE.ILLUSTRATIONS ? asset.assetId : asset.id
    }`,
    route: getRouteFromAssetType(asset.type),
  }));

  return pagination;
};

export const hasNextPage = (paginatable: Paginatable) => {
  return paginatable.total > paginatable.offset + paginatable.limit;
};

export const hasPreviousPage = (paginatable: Paginatable) => {
  return paginatable.offset !== 0;
};

const getPaginationUrl = (
  url: string,
  directive: 'next' | 'previous',
  params: { [string]: any } | void
) => {
  if (typeof params !== 'object') return '';

  const clonedParams = { ...params };
  let final = '?';

  // First page typically does not have page params in URL
  if (!params.page) {
    clonedParams.page = 1;
  }

  // sort params alphabetically
  const sorted = Object.keys(clonedParams).sort();
  clonedParams.page =
    directive === 'next'
      ? Number(clonedParams.page) + 1
      : Number(clonedParams.page) - 1;

  sorted.forEach((param, index) => {
    final += `${param}=${clonedParams[param]}`;

    if (index !== sorted.length - 1) {
      final += '&';
    }
  });

  return (url + final).toLowerCase();
};

export const setPaginationTags = (
  paginatable: Paginatable,
  url: string,
  params: { [string]: any } | void
) => {
  if (!paginatable) return [];
  if (paginatable.total <= 100) return [];

  let links = [];
  let currentPage = params?.page || 0;

  if (hasNextPage(paginatable) && currentPage < 100) {
    const nextUrl = getPaginationUrl(url, 'next', params);

    links.push({
      rel: 'next',
      href: nextUrl,
    });
  }
  if (hasPreviousPage(paginatable)) {
    const prevUrl = getPaginationUrl(url, 'previous', params);
    links.push({
      rel: 'prev',
      href: prevUrl,
    });
  }

  return links;
};
