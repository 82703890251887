// @flow

import { createElement } from 'react';
import { setPaginationTags } from './pagination';
import { PRODUCTION_BASEURL, DEFAULT_LANGUAGE } from '../constants/misc';

type RenderLinkArgs = {
  language: string,
  paginatable?: Paginatable,
  path: string,
  query?: Object,
};

export const renderLinkTags = ({
  language,
  paginatable,
  path,
  query,
  skipCanonical = false,
}: RenderLinkArgs) => {
  const isEnglish = language === DEFAULT_LANGUAGE;
  const page = query?.page;
  let links = [];
  let href = `${PRODUCTION_BASEURL}${path.toLowerCase()}`;

  if (paginatable) {
    links = setPaginationTags(paginatable, href, query);
  }
  if (page) {
    href = `${href}${page !== '1' ? `?page=${page}` : ''}`;
  }
  if (isEnglish && !skipCanonical) links.push({ rel: 'canonical', href });

  return (links.map((link, index) => {
    const keyname = Object.keys(link)[0];
    return createElement('link', {
      ...link,
      key: `link-${keyname + index}`,
    });
  }): React$Node[]);
};
