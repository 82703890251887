/* @flow */
import NotFound from '../notFound.jsx';

function ErrorMsg(props: {
  code: number | void,
  resourceType: string,
  message: string | void,
}) {
  const style = {
    height: '100%',
  };

  return (
    <div style={style}>
      {props.code === 404 ? (
        <NotFound resourceType={props.resourceType} />
      ) : (
        <div className="g_centered_wrapper empty-grid">
          <div className="g_centered">
            <img alt="sad face" src="/node-static/img/sadsev.png" />
            <h2>
              {props.code} - {props.message}
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}

export default ErrorMsg;
