// @flow
import t from 'counterpart';
import { Helmet } from 'react-helmet-async';

const ErrorMeta = ({ statusCode }: { statusCode: number }) => {
  const title = statusCode === 404 ? t('meta.title.notFound') : '';

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default ErrorMeta;
