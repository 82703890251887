/** @jsxRuntime classic */
/** @jsx jsx */
/* @flow */
import { jsx } from 'theme-ui'; // eslint-disable-line
import t from 'counterpart';
import styled from '@emotion/styled';
import { Text, Box, Button, Flex } from '@eyeem-ui/atoms';
import { track } from '../../../helpers/tools';

import Photo from '../photo/';

const Strip = styled.div`
  padding-bottom: 16px;
  cursor: pointer;
`;

const StyledPhoto = styled(Photo)`
  height: 36px;
  margin-right: 4px;
  display: inline-block;
`;

const Photos = styled.div`
  height: 36px;
  position: relative;
  white-space: nowrap;
  text-align: left;
`;

function SelectionStrip(props: {
  firstName?: string,
  lastName?: string,
  photos: $ReadOnlyArray<EyeEmPhoto>,
  headlineTranslate: string,
  className: string,
  buttonAction: Function,
  canEdit: boolean,
  eventOption: Object,
}) {
  const headline = props.firstName
    ? t('multipleReleases.selectionStrip.headlineWithName', {
        firstName: props.firstName,
        lastName: props.lastName,
        boldCount: props.photos?.length,
        count: props.photos?.length,
      })
    : t('multipleReleases.selectionStrip.headline', {
        boldCount: props.photos?.length,
        count: props.photos?.length,
      });

  return (
    <Strip className={props.className} onClick={props.buttonAction}>
      <Box py={3}>
        <Text>
          {props.headlineTranslate
            ? t(props.headlineTranslate, {
                boldCount: props.photos?.length,
                count: props.photos?.length,
                firstName: props.firstName,
                lastName: props.lastName,
              })
            : headline}
        </Text>
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Box sx={{ overflow: 'hidden' }}>
          <Photos>
            {props.photos &&
              props.photos
                .slice(0, 6)
                .map((photo: EyeEmPhoto) =>
                  photo ? (
                    <StyledPhoto
                      key={photo.id}
                      url={photo.thumbUrl || photo.previewUrl}
                      size1="h"
                      size2="36"
                    />
                  ) : null
                )}
          </Photos>
        </Box>
        <Box pl="2" flex="0 0 auto">
          {props.canEdit ? (
            <Button
              variant="subtle"
              onClick={() => {
                track({
                  eventName: t(
                    'multipleReleases.selectionStrip.edit.eventName'
                  ),
                  eventAction: t('tracking.eventAction.button'),
                  eventLabel: t('multipleReleases.selectionStrip.edit.label'),
                  eventPosition: t(
                    'multipleReleases.selectionStrip.eventPosition'
                  ),
                  eventType: t('tracking.eventType.inbound'),
                  eventOption: props.eventOption,
                });
                props.buttonAction();
              }}>
              {t('multipleReleases.selectionStrip.edit.label')}
            </Button>
          ) : (
            <Button
              variant="subtle"
              onClick={() => {
                track({
                  eventName: t(
                    'multipleReleases.selectionStrip.view.eventName'
                  ),
                  eventAction: t('tracking.eventAction.button'),
                  eventLabel: t('multipleReleases.selectionStrip.view.label'),
                  eventPosition: t(
                    'multipleReleases.selectionStrip.eventPosition'
                  ),
                  eventType: t('tracking.eventType.inbound'),
                  eventOption: props.eventOption,
                });
                props.buttonAction();
              }}>
              {t('multipleReleases.selectionStrip.view.label')}
            </Button>
          )}
        </Box>
      </Flex>
    </Strip>
  );
}

export default SelectionStrip;
