// @flow
import { gql } from '@apollo/client';

import { SimplePhotoFragment } from '../fragments/photo';
import { SimpleUserFragment } from '../fragments/user';

export const GET_ALBUM_HEAD = gql`
  query getAlbumHead($albumId: ID!, $isBuyer: Boolean!) {
    album(albumId: $albumId, isBuyer: $isBuyer) {
      id
      name
      totalPhotos
      totalContributors
      favorited
      type
      coverPhoto {
        previewUrl: photoUrl
      }
      blacklisted
      location {
        latitude
        longitude
        cityAlbum {
          id
          name
        }
        countryAlbum {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALBUM_PHOTOS = gql`
  query getAlbumPhotos(
    $albumId: ID!
    $offset: Int
    $limit: Int
    $isBuyer: Boolean!
    $sort: String
  ) {
    album(albumId: $albumId, isBuyer: $isBuyer) {
      id
      name
      totalPhotos
      totalContributors
      favorited @skip(if: $isBuyer)
      type
      coverPhoto {
        previewUrl: photoUrl
      }
      location {
        latitude
        longitude
        cityAlbum {
          id
          name
        }
        countryAlbum {
          id
          name
        }
      }
      blacklisted
      photos(offset: $offset, isBuyer: $isBuyer, limit: $limit, sort: $sort) {
        total
        offset
        limit
        items {
          ...SimplePhoto
          type
          blacklisted
          liked @skip(if: $isBuyer)
          totalLikes @skip(if: $isBuyer)
          totalComments @skip(if: $isBuyer)
          updated
          updatedAt
          eyevision {
            headline
            tags
          }
          lightboxIds @include(if: $isBuyer)
          user @skip(if: $isBuyer) {
            ...SimpleUser
          }
        }
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;

export const GET_ALBUM_PHOTOS_FOR_SINGLE_PHOTO = gql`
  query getAlbumPhotosForSinglePhoto(
    $albumId: ID!
    $offset: Int
    $limit: Int
    $isBuyer: Boolean!
    $sort: String
  ) {
    album(albumId: $albumId, isBuyer: $isBuyer) {
      id
      photos(offset: $offset, limit: $limit, isBuyer: $isBuyer, sort: $sort) {
        total
        offset
        items {
          ...SimplePhoto
        }
      }
    }
  }
  ${SimplePhotoFragment}
`;

export const GET_ALBUM_FOLLOWING = gql`
  query getAlbumFollowing($albumId: ID!, $isBuyer: Boolean!) {
    album(albumId: $albumId, isBuyer: $isBuyer) {
      id
      favorited
      photos {
        offset
        items {
          id
        }
      }
      contributors {
        offset
        items {
          id
        }
      }
    }
  }
`;

export const GET_ALBUM_USERS = gql`
  query getAlbumUsers($albumId: ID!, $offset: Int, $isBuyer: Boolean!) {
    album(albumId: $albumId, isBuyer: $isBuyer) {
      id
      favorited
      type
      totalPhotos
      totalContributors
      name
      contributors(offset: $offset) {
        offset
        total
        items {
          ...SimpleUser
          following
          follower
          totalFollowers
          totalPhotos
          totalFriends
        }
      }
    }
  }
  ${SimpleUserFragment}
`;
