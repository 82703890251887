/* @flow */
import classnames from 'classnames';
import SelectGridItem from './selectGridItem.jsx';

function SelectGrid(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  toggleSelection?: Function, // used inside a .map()
  // eslint-disable-next-line react/no-unused-prop-types
  removePhoto?: Function, // used inside a .map()
  // eslint-disable-next-line react/no-unused-prop-types
  lowResolution?: number, // used inside a .map()
  selection: $ReadOnlyArray<string>,
  photos: $ReadOnlyArray<UploadPhoto>,
  disabled: boolean,
  className?: string,
  handleClickOnBackground?: Function,
  contentToLeft?: boolean,
}) {
  return (
    <div
      onClick={props.handleClickOnBackground}
      className={classnames('selectGrid', props.className, {
        cw_disabled: props.disabled,
        'selectGrid-contentToLeft': props.contentToLeft,
      })}>
      {props.photos &&
        props.photos.map((photo) =>
          photo ? (
            <SelectGridItem
              removePhoto={props.removePhoto}
              toggleSelection={props.toggleSelection}
              lowResolution={props.lowResolution}
              key={photo.uuid || photo.id}
              photo={photo}
              selection={props.selection}
            />
          ) : null
        )}
    </div>
  );
}

export default SelectGrid;
