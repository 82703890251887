/* @flow */
import { connect } from 'react-redux';

import SelectionStrip from './selectionStrip.jsx';

const mapStateToProps = (state, ownProps) => ({
  photos: ownProps.photos,
});

export default connect(mapStateToProps)(SelectionStrip);
